import React,{useState} from "react";
import './fas.css';
import {Link, Redirect} from 'react-router-dom';
import firebase from '../../config/firebase';
import Logo from '../../img/diproposito.png'
import camaleo from '../../img/Camaleo.png';

const db = firebase.firestore();
function Fas(){
  const [msgTipo, setMsgtipo] = useState('');
    const [nomefa, setNomefa] = useState('');
    const [emailfa, setEmailfa] = useState('');
    const [telefonefa, setTelefonefa] = useState('');
    const [estadofa, setEstadofa] = useState('');
    const [cidadefa, setCidadefa] = useState('');
    const [nascimentofa, setNascimentofa] = useState('');
  
      function registrafa() {
          db.collection('fas').add({
            nome: nomefa,
            email: emailfa,
            telefone: telefonefa,
            estado: estadofa,
            cidade: cidadefa,
            nascimetno: nascimentofa
          }).then(resultado =>{
            console.log('deu certo');

          }).catch(erro =>{
            console.log('deu ruim')
          })
        }
  
    return(
        <>

<div className="container mx-auto text-center">
    <Link to="/" className="btn btn-lg btn-outline-dark mx-auto text-center mt-4 mb-4">Voltar</Link>
</div>

<div class="container cardcor mt-4 text-center">
    <form class="form-signin">
      <img class="mb-4 mt-4 mx-auto text-center" src={Logo} alt="" width="300px"  />
      <h1 class="h3 mb-3 featurette-heading mt-4 text-white">Cadastro de Fã</h1>
      <input  onChange={(e) => setNomefa(e.target.value) } className="form-control mt-2" placeholder="Seu nome" />
    <input  onChange={(e) => setEmailfa(e.target.value) } className="form-control mt-2" placeholder="Email " />
    <input className="form-control mt-2" placeholder="Confirmar Email " />
    <input  onChange={(e) => setTelefonefa(e.target.value) } className="form-control mt-2" placeholder="telefone (Whatsapp)" />
    <input  onChange={(e) => setEstadofa(e.target.value) } className="form-control mt-2" placeholder="Estado" />
    <input  onChange={(e) => setCidadefa(e.target.value) } className="form-control mt-2" placeholder="Cidade" />
    <input  onChange={(e) => setNascimentofa(e.target.value) } className="form-control mt-2 " placeholder="Data de nascimento" />
    <select class="form-control mt-2">
  <option>Contato por WhatsApp</option>
  <option>Sim</option>
  <option>nao</option>
</select>
    <div class="form-check mt-3">
     
      <label class="form-check-label text-white text-center mb-3 " for="gridCheck">
      Ao clicar "Registrar" você está aceitando os <a href=""><Link to="/termos" className=" btn-sm btn-outline-primary">termos de uso</Link></a>
      </label>
    </div>
    <Link to="/concluido"><button className="btn btn-outline-light btn-lg btn-block mt-2 mb-4" onClick={registrafa}>Registrar</button></Link>
    </form>
  </div>
  <div className="container">
  <p className="text-center mt-4 mb-4">Desenvolvido por <a href="https://www.instagram.com/camaleocreative/"><img src={camaleo} width="92px" className="mb-2" /></a></p>
  </div>

  </>
    );
}

export default Fas;
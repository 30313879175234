import React,{useState} from "react";
import './faclube.css';
import {Link, Redirect} from 'react-router-dom';
import firebase from '../../config/firebase';
import Logo from '../../img/diproposito.png'
import camaleo from '../../img/Camaleo.png';

const db = firebase.firestore();
function Faclube(){


    const [nomefaclube, setNomefaclube] = useState('');
    const [emailfaclube, setEmailfaclube] = useState('');
    const [telefonefaclube, setTelefonefaclube] = useState('');
    const [estadofaclube, setEstadofaclube] = useState('');
    const [cidadefaclube, setCidadefaclube] = useState('');
    const [nascimentofaclube, setNascimentofaclube] = useState('');
    const [facebookfaclube, setFacebookfaclube] = useState('');
    const [instafaclube, setInstafaclube] = useState('');
    const [tiktokfaclube, setTiktokfaclube] = useState('');
    const [descricaofaclube, setDescricaofaclube] = useState('');

    function registrafaclube() {
        db.collection('faclube').add({
          nome: nomefaclube,
          email: emailfaclube,
          telefone: telefonefaclube,
          estado: estadofaclube,
          cidade: cidadefaclube,
          nascimetno: nascimentofaclube,
          descricão: descricaofaclube,
          insta: instafaclube,
          tiktok: tiktokfaclube,
          face: facebookfaclube
        }).then(resultado =>{
          console.log('deu certo')
        }).catch(erro =>{
          console.log('deu ruim')
        })
      }



    return(
        <>

        <div className="container mx-auto text-center">
        <Link to="/" className="btn btn-lg btn-outline-dark mx-auto text-center mt-4 mb-4">Voltar</Link>
        </div>
        
        <div class="container cardcor mt-4 text-center">
            <form class="form-signin">
              <img class="mb-4 mt-4 mx-auto text-center" src={Logo} alt="" width="300px"  />
              <h1 class="h3 mb-3 featurette-heading mt-4 text-white">Cadastro de Fã Clubes</h1>
              <input onChange={(e) => setNomefaclube(e.target.value) } className="form-control mt-2" placeholder="Nome do fã clube" />
    <textarea onChange={(e) => setDescricaofaclube(e.target.value) } className="form-control mt-2" placeholder="Descrição do Fã clube" row="3" />
    <input onChange={(e) => setNascimentofaclube(e.target.value) } type="data" className="form-control mt-2"  placeholder="Data de fundação" />
    <input onChange={(e) => setEstadofaclube(e.target.value) } className="form-control mt-2" placeholder="Estado" />
    <input onChange={(e) => setCidadefaclube(e.target.value) } className="form-control mt-2" placeholder="Cidade" />
    <input onChange={(e) => setFacebookfaclube(e.target.value) } className="form-control mt-2" placeholder="Facebook" />
    <input onChange={(e) => setInstafaclube(e.target.value) } className="form-control mt-2" placeholder="Instagram" />
    <input onChange={(e) => setTiktokfaclube(e.target.value) } className="form-control mt-2" placeholder="TikTok" />
    <input onChange={(e) => setEmailfaclube(e.target.value) } className="form-control mt-2" placeholder="Email " />
    <input onChange={(e) => setTelefonefaclube(e.target.value) } className="form-control mt-2" placeholder="telefone" />
    <select class="form-control mt-2">
  <option>Contato por WhatsApp</option>
  <option>Sim</option>
  <option>nao</option>
</select>
            <div class="form-check mt-3">
              
              <label class="form-check-label text-white text-center" for="gridCheck">
              Ao clicar "Registrar" você está aceitando os <a href=""><Link to="/termos" className=" btn-sm btn-outline-primary">termos de uso</Link></a>
              </label>
            </div>
            <Link to="/concluido"><button className="btn btn-outline-light btn-lg btn-block mt-2 mb-4" onClick={registrafaclube}>Registrar</button></Link>
            </form>
          </div>
          <div className="container">
          <p className="text-center mt-4 mb-4">Desenvolvido por <a href="https://www.instagram.com/camaleocreative/"><img src={camaleo} width="92px" className="mb-2" /></a></p>
          </div>
          </>
    )
}

export default Faclube;
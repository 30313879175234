import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyBnHvOEXYXF5Bru1jyLpVfCx0cs8FeoH_o",
  authDomain: "landingpageka.firebaseapp.com",
  projectId: "landingpageka",
  storageBucket: "landingpageka.appspot.com",
  messagingSenderId: "402686630134",
  appId: "1:402686630134:web:43de53eb46b0ff926734d7",
  measurementId: "G-2SZW9KZBV6"
};


export default firebase.initializeApp(firebaseConfig);

import React,{useState} from "react";
import './concluido.css';
import {Link, Redirect} from 'react-router-dom';
import camaleo from '../../img/Camaleo.png';
import insta from '../../img/insta.png';
import youtube from '../../img/youtube.png';
import spotify from '../../img/spotify.png';

function Concluido(){
    return(
        <>

<div class="cardcor2 mx-auto mt-4   ">
  <div class="container">
    <h2 class="featurette-heading text-center textcor">Cadastro conluído!</h2>
    <Link to="/" className="mt-4 mb-2 btn btn-lg btn-outline-dark btn-block ">Voltar para Home</Link>
    </div>
    </div>

    <hr class="featurette-divider" />

<div class="cardcor2 mx-auto    ">
  <div class="container">
    <h2 class="featurette-heading">Escute de onde e aonde quiser o grupo de pagode de Brasília,  <span class="textcor">que fez o Brasil todo Mandar Áudio. </span></h2>
  <a href="https://open.spotify.com/artist/2uSwBbWqsXgoMYQjynmwQ7?si=8hQA_Z3rRMWon0WFQqQPsg" className="btn btn-lg mt-4 mb-2 btn-outline-dark btn-block"><img src={spotify} width="30px" /> Spotify</a>
  <a href="https://www.youtube.com/channel/UCtdqRFoUfC7_SAiIRQiBbGg" className="btn btn-lg btn-outline-dark  mt-4 mb-2 btn-block"><img src={youtube} width="30px" /> Youtube</a>
  <a href="https://www.instagram.com/diproposito/" className="mt-4 mb-2 btn btn-lg btn-outline-dark btn-block "><img src={insta} width="30px" /> Instagram</a>
  </div>
</div>

<div className="container">
          <p className="text-center mt-4 mb-4">Desenvolvido por <a href="https://www.instagram.com/camaleocreative/"><img src={camaleo} width="92px" className="mb-2" /></a></p>
          </div>


          </>
    )
}

export default Concluido;
import React,{useState} from "react";
import './home.css';
import {Link} from 'react-router-dom';
import Logo from '../../img/diproposito.png';
import Capa1 from '../../img/capa.jpg';
import Capa2 from '../../img/assita.jpg';
import Capa3 from '../../img/grupo.jpg';
import Perfil from '../../img/perfil.jpeg';
import spotify from '../../img/spotify.png';
import verificado from '../../img/verificado.png';
import insta from '../../img/insta.png';
import youtube from '../../img/youtube.png';
import camaleo from '../../img/Camaleo.png';





function App() {

  

  return (
    <>
    <div className="collapse fixed-top mt-4 bgmenu" id="navbarHeader">
    <div className="container mt-4">
      <div className="row">
        <div className="col-sm-8 col-md-7 py-4">
          <h4 className="text-white">Sobre</h4>
          <p className=" text-white">Os meninos de Brasília que resolveram MANDAR ÁUDIO pro mundo inteiro.</p>
        </div>
        <div className="col-sm-4 offset-md-1 py-4">
          <h4 className="text-white text-center">Cadastrar</h4>
          <ul className="list-unstyled">
            <Link to="/fas" className="btn btn-outline-light btn-block mt-1">Sou fã</Link>
            <Link to="/faclube" className="btn btn-outline-light btn-block mt-1">Tenho um fã clube</Link>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div className="navbar fixed-top navbar-dark bgmenu box-shadow">
    <div className="container d-flex justify-content-between">
      <a href="#" className="navbar-brand d-flex align-items-center">
        <img src={Logo} width="200px" />
      </a>
      <button className="navbar-toggler " type="button" data-toggle="collapse" data-target="#navbarHeader" aria-controls="navbarHeader" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon text-white"></span>
      </button>
    </div>
  </div>

<div className="container mt-4">
  <div class="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bghome">
      <div class="col-md-5 p-lg-5 mx-auto my-5">
        <img src={Perfil} className="mx-auto text-center perfil" width="250px" />
        <h1 class=" font-weight-small"><img src={verificado} width="30px" className="mb-3" /> Di Próposito</h1>
        <p class="lead font-weight-normal mt-3">Bem vindo(a), a Central de Fãs do Grupo Di Propósito</p>
        <Link to="/fas" class="btn corcad text-white btn-lg btn-block">Sou fã</Link>
        <Link to="/faclube" class="btn corcad text-white btn-lg btn-block">Tenho um fã clube</Link>
      </div>
      <div class="product-device box-shadow d-none d-md-block"></div>
      <div class="product-device product-device-2 box-shadow d-none d-md-block"></div>
    </div>
    </div>

    <div className="container">
    <hr class="featurette-divider" />

<div class="row featurette">
  <div class="col-md-7">
    <h2 class="featurette-heading">Escute de onde e aonde quiser o grupo de pagode de Brasília,  <span class="textcor">que fez o Brasil todo Mandar Áudio. </span></h2>
  <a href="https://open.spotify.com/artist/2uSwBbWqsXgoMYQjynmwQ7?si=8hQA_Z3rRMWon0WFQqQPsg" className="btn btn-lg mt-4 mb-2 corcad text-white btn-block"><img src={spotify} width="30px" /> Spotify</a>
  </div>
  <div class="col-md-5">
    <img class="featurette-image img-fluid mx-auto" src={Capa1} alt="Generic placeholder image" />
  </div>
</div>


<hr class="featurette-divider" />

<div class="row featurette">
  <div class="col-md-7 order-md-2">
    <h2 class="featurette-heading">Nada é por acaso. É tudo Di Propósito!  <span class="textcor">Aperte o play e assista os melhores blocos de pagode da atualidade. </span></h2>
    <a href="https://www.youtube.com/channel/UCtdqRFoUfC7_SAiIRQiBbGg" className="btn btn-lg  corcad text-white  mt-4 mb-2 btn-block"><img src={youtube} width="30px" /> Youtube</a>
  </div>
  <div class="col-md-5 order-md-1">
    <img class="featurette-image img-fluid mx-auto" src={Capa2} alt="Generic placeholder image" />
  </div>
</div>

<hr class="featurette-divider" />

<div class="row featurette">
  <div class="col-md-7">
    <h2 class="featurette-heading my-auto ">A gente não é novela, mas pode ser acompanhado!<span class="textcor">Siga nossa conta no Instagram  e fique por dentro da agenda de shows e dos bastidores do Di Propósito.</span></h2>
  
    <a href="https://www.instagram.com/diproposito/" className="mt-4 mb-2 btn btn-lg btn-block corcad text-white"><img src={insta} width="30px" /> Instagram</a>
  </div>
  <div class="col-md-5">
    <img class="featurette-image img-fluid mx-auto" src={Capa3} alt="Generic placeholder image" />
  </div>
</div>

<hr class="featurette-divider" />
</div>




<footer class="container">
<p class="float-right"><a href="#" className="btn btn-outline-warning btn-sm my-auto">Topo da página</a></p>
<p>Desenvolvido por <a href="https://www.instagram.com/camaleocreative/"><img src={camaleo} width="92px" className="mb-2" /></a></p>
</footer>






      
   

  </>
  );
}

export default App;
import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';


import Home from '../src/views/home/';
import Fas from '../src/views/fas';
import Faclube from '../src/views/faclube/';
import Concluido from '../src/views/concluido/';
import Termos from '../src/views/termos/';


function App() {
  return (

    <BrowserRouter>
      <Route exact path='/' component={Home} />
      <Route exact path='/fas' component={Fas} />
      <Route exact path='/faclube' component={Faclube} />
      <Route exact path='/concluido' component={Concluido} />
      <Route exact path='/termos' component={Termos} />
    </BrowserRouter>

  );
}

export default App;
